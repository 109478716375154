<template>
  <div>
    <b-modal
      v-model="isDirectDepositModalActive"
      title="เครดิตฟรี"
      hide-footer
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <b-form-group
            label-cols-sm="12"
            label-cols-lg="3"
            :label="`${$t('user.firstname')} ${$t('user.lastname')}`"
            label-for="name"
          >
            <b-form-input
              id="name"
              :value="`${player.firstname || ''} ${player.lastname || ''}`"
              readonly
            ></b-form-input>
          </b-form-group>

          <!-- <ValidationProvider
            v-slot="{ errors }"
            :name="$t('credits.credit_amount')"
            rules="required|min_value:1"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('credits.credit_amount')} *`"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="transferForm.amount"
                type="number"
                :state="errors[0] ? false : null"
              ></b-form-input>
            </b-form-group>
          </ValidationProvider> -->

          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              rounded
              spinner-small
              class="d-inline-block"
              :show="loading"
            >
              <b-button
                type="submit"
                variant="primary"
                :disabled="loading"
                block
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FreeCreditModal',
  components: {},
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
    player: {
      type: Object,
      default: () => {},
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isDirectDepositModalActive: false,
      transferForm: {
        amount: 0,
        detail: '',
      },
    }
  },
  computed: {
    loading: (state) => state.player.isAddingPlayerFreeCredit,
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['addFreeCreditPlayer']),
    setData() {
      this.transferForm = {
        amount: 100,
        detail: '',
      }
    },
    toggleModal() {
      this.isDirectDepositModalActive = !this.isDirectDepositModalActive
    },
    onCloseModal() {
      this.isDirectDepositModalActive = false
    },
    onUpdateUploadedFile() {},
      async onSubmit() {
      await this.addFreeCreditPlayer({
        playerId: this.player.id,
        search: this.search
      })
      this.onCloseModal();
      this.$emit('fetch');
    },
  },
}
</script>
